.footer {
  color: #fff;

  a {
    display: block;
    color: #fff;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #fff;
    margin-top: 0;
  }
}