.vehicles {

  .vehicle {
    padding: 1rem .75rem;
    border-bottom: 1px solid var(--border);

    img {
      height: 40px;
    }
  }
}