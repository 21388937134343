.vehicle-box {
  border: 1px solid var(--border);
  height: 100%;
  background-color: #fff;

  .vehicle-image {
    height: 0;
    padding-bottom: 66.5%;
    position: relative;
    margin-bottom: 1rem;
    background-color: var(--border);

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .vehicle-info {
    margin: 1rem;
  }
}