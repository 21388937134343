.inventory-box {
  border: 1px solid var(--border);
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .inventory-image {
    height: 0;
    padding-bottom: 66.5%;
    position: relative;
    margin-bottom: 1rem;
    background-color: var(--border);

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .inventory-info {
    margin: 0 1rem;
    flex: 1;
  }

  .inventory-title {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .inventory-subtitle {
    font-weight: bold;
    height: 44px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .inventory-table {
    font-size: .8rem;
    margin: 1rem 0;

    .ant-row {
      border-bottom: 1px solid var(--border);
    }

    .ant-col:last-child {
      text-align: right;
    }
  }

  .inventory-action {
    margin: 1rem;
  }
}