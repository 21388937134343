// must be mapped in Theme.tsx
:root {
  --primary: #090;
  --border: #eee;
  --body: #fff;
}

body {
  margin: 0;
  background-color: var(--body);
}

h1, h2, h3 {
  text-transform: uppercase;
}

img {
  max-width: 100%;
}

p:first-child {
  margin-top: 0;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hyphenate {
  word-wrap: break-word;
  overflow-wrap: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.primary-bg {
  background-color: rgba(var(--primary), .25)
}

.primary-border {
  border: 1px solid rgba(var(--primary), .25);
}

.ant-carousel {
  background-color: rgba(0, 0, 0, .5);
  margin-bottom: 1.5rem;

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-dots-bottom {
    bottom: -1rem;
  }

  .slick-dots li button {
    background-color: var(--primary);
  }

  .slick-dots li.slick-active button {
    background-color: var(--primary);
  }
}

.ant-menu-item {
  &:hover {
    a, svg {
      color: var(--primary) !important;
    }
  }
}

.ant-card:not(:first-child) {
  margin-top: 1.5rem;
}

.ant-card-head-title {
  text-transform: uppercase;
  font-size: 18px;
}

.ant-checkbox {
  align-self: flex-start;
  margin-top: .25rem;
}