.ssys-category-page {

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
  }

  .mode-boxed {
    max-width: 1000px;
    margin: 0 auto;
  }

  .mode-full_width {
    margin: 0 -1.25rem;
    // must match layout padding!
  }
}