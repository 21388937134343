.dashboard-contact {
  .contact-photo {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
  }

  .contact-label {
    font-size: 1.25rem;
  }
}