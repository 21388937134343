.product-box {
  position: relative;
  border: 1px solid var(--border);
  background-color: #fff;

  height: 100%;
  display: flex;
  flex-direction: column;

  .product-image {
    height: 0;
    padding-bottom: 66.5%;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .product-wishlist {
    position: absolute;
    top: 1rem;
    right: 1rem;

    border-radius: 50%;
    background-color: var(--border);
    width: 2rem;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    font-size: 1.1rem;

    svg {
    }
  }

  .product-info {
    flex: 1;
    margin: 1rem;
  }

  .product-action {
    margin: 0 1rem 1rem;

    button {
      width: 100%;
    }
  }

  .product-name {
    font-weight: bold;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .product-number {
    margin-bottom: 1rem;
  }

  .product-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .product-price {
    margin: 0 1rem 1rem;
  }
}