.route-tiles {
  width: 100%;
  padding: .75rem 0;

  display: flex;
  gap: 1.5rem;

  overflow-x: auto;

  .tile {

    svg {
      margin-top: .5rem;
      color: var(--primary);
    }

    .ant-card-cover {
      padding-top: 1rem;
    }

    .ant-card-body {
      text-align: center;
    }
  }
}